import './SettingsRolesContent.less'

import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import { RoleData, fetchRoles } from '../../../requests'
import { DatacLoading, DatacMessage } from '../../common'
import { SettingsLayout } from '../SettingsLayout'
import { SettingsRolesAddModal } from './SettingsRolesAddModal'
import { SettingsRolesTable } from './SettingsRolesTable'
import { SettingsRolesViewAndEdit } from './SettingsRolesViewAndEdit'

const pageSize = 25

export const SettingsRolesContent: React.FC = () => {
  const intl = useScopedIntl('')
  const [roles, setRoles] = useState<RoleData[]>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [allCount, setAllCount] = useState(0)
  const [isFetchingRoles, setIsFetchingRoles] = useState(false)
  const [isViewingRole, setIsViewingRole] = useState(false)
  const [roleToViewId, setRoleToViewId] = useState<string>(null)
  const [isRoleEditDisabled, setIsEditDisabled] = useState(false)
  const [isAddModalOpened, setIsAddModalOpened] = useState(false)
  const [currentPageSize, setCurrentPageSize] = useState(pageSize)

  useEffect(() => {
    fetchRolesPage(0)
  }, [currentPageSize])

  const onPageChange = (page: number) => {
    fetchRolesPage(page - 1)
  }

  const fetchRolesPage = (page: number) => {
    setIsFetchingRoles(true)
    return fetchRoles(
      { options: { limit: currentPageSize, offset: page * currentPageSize } },
      {
        onSuccess: ({ roles, allUsersCount }) => {
          setRoles(roles)
          setAllCount(allUsersCount)
          setIsFetchingRoles(false)
          setCurrentPage(page + 1)
        },
        onRequestError: code => {
          setIsFetchingRoles(false)
          DatacMessage.genericError(intl, code)
        }
      }
    )
  }

  const onViewRole = (id: string, isEditDisabled: boolean) => {
    setRoleToViewId(id)
    setIsEditDisabled(isEditDisabled)
    setIsViewingRole(true)
  }

  const onGoBack = () => {
    setIsViewingRole(false)
    fetchRolesPage(currentPage - 1)
  }

  return (
    <SettingsLayout>
      <div className="settings-roles-content">
        {isViewingRole ? (
          <SettingsRolesViewAndEdit roleId={roleToViewId} isRoleEditDisabled={isRoleEditDisabled} onGoBack={onGoBack} />
        ) : (
          <DatacLoading isLoading={isFetchingRoles}>
            <SettingsRolesTable
              roles={roles}
              currentPage={currentPage}
              onPageChange={onPageChange}
              totalCount={allCount}
              pageSize={currentPageSize}
              setPageSize={setCurrentPageSize}
              onViewRole={onViewRole}
              onAddNewRole={() => setIsAddModalOpened(true)}
            />
          </DatacLoading>
        )}
      </div>
      <SettingsRolesAddModal
        isOpened={isAddModalOpened}
        onClose={() => setIsAddModalOpened(false)}
        onRoleAdded={() => fetchRolesPage(0)}
      />
    </SettingsLayout>
  )
}
