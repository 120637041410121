import './SettingsRolesTable.less'

import { Button } from 'antd'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { RoleData } from '../../../../requests'
import { TableRecord, createTableRecords } from '../../../../utils'
import { DatacIcon, DatacTable, DatacTitle } from '../../../common'
import { getColumns } from './SettingsRolesTableConfig'

interface Props {
  roles: RoleData[]
  onViewRole: (id: string, isEditingDisabled: boolean) => void
  onAddNewRole: () => void
  currentPage: number
  onPageChange: (page: number) => void
  totalCount: number
  pageSize: number
  setPageSize: (pageSize: number) => void
}

export const SettingsRolesTable: React.FC<Props> = ({
  roles,
  currentPage,
  onPageChange,
  totalCount,
  pageSize,
  setPageSize,
  onViewRole,
  onAddNewRole
}) => {
  const intlRoles = useScopedIntl('settings.roles')
  const [roleRows, setRoleRows] = useState<TableRecord<RoleData>[]>([])

  useEffect(() => {
    setRoleRows(createTableRecords<RoleData>(roles))
  }, [roles])

  const columns = getColumns({
    columnNames: {
      role: intlRoles('column.role'),
      users: intlRoles('column.users'),
      actions: ''
    },
    onViewRole
  })

  return (
    <>
      <div className="settings-roles-content__header">
        <DatacTitle type="h2">{intlRoles('title')}</DatacTitle>
        <div className="settings-roles-content__header-controls">
          <Button className="settings-roles-content__add-button" type="primary" size="large" onClick={onAddNewRole}>
            <DatacIcon type="white" name="plus" size="big" />
            {intlRoles('add_role')}
          </Button>
        </div>
      </div>
      <div className="settings-roles-table">
        <DatacTable
          dataSource={roleRows}
          columns={columns}
          rowSelection={undefined}
          scroll={{ x: 500 }}
          pagination={{
            current: currentPage,
            onChange: onPageChange,
            total: totalCount,
            pageSize
          }}
          setPageSize={setPageSize}
        />
      </div>
    </>
  )
}
